import { initializeApp, getApps } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const initFirebase = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyAICO2YQ5lUi30Z52UXtF3d8LPHumKm7q8",
    authDomain: "insureinapp.firebaseapp.com",
    projectId: "insureinapp",
    storageBucket: "insureinapp.appspot.com",
    messagingSenderId: "554766735240",
    appId: "1:554766735240:web:7d1a0ecd665939bf110c3d",
    measurementId: "G-0J6WQH0JYD"
  };
  
  // Initialize Firebase
  const apps = getApps();
  if (apps.length === 0) {
    const app = initializeApp(firebaseConfig);
    getAnalytics(app);
  }
};

export default initFirebase;
