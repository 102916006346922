import React from 'react';

interface Props{
  type: string;
  label: string;
  placeholder: string;
  value: string;
  onChange: (e:React.ChangeEvent<HTMLInputElement>) => void;
  valid?: string;
  validMsg?: string;
  maxLength?: number;
}

const TextInput:React.FC<Props> = (props) => {
  return (
    <div className='text-input-wrap'>
      <div className='input-label'>{props.label}</div>
      <input className='text-input' type={props.type} value={props.value} placeholder={props.placeholder} onChange={props.onChange} maxLength={props.maxLength}/>
      {
        props.valid === 'error' && props.validMsg ?
        <div className='input-error'>{props.validMsg}</div> : null
      }
    </div>
  );
};

export default TextInput;