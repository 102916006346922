import React, { useRef, useState } from 'react';
import TextInput from '../components/input';
import Button from '../components/button';

const AlarmForm = () => {
  const [agree, setAgree] = useState(false);
  const agreeToggle = () => {
    setAgree(!agree);
    setAgreeValid('');
  };
  const [agreeValid, setAgreeValid] = useState('');

  const formRef = useRef<HTMLDivElement | null>(null);
  const moveForm = () => {
    formRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [mobile, setMobile] = useState('');
  const [mail, setMail] = useState('');
  const mobileChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setMobile(e.target.value);
    setMobileValid('');
  };
  const mailChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setMail(e.target.value);
    setMailValid('');
  };

  const [mobileValid, setMobileValid] = useState('');
  const [mailValid, setMailValid] = useState('');

  const isValid = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const birthPattern = /^\d{8}$/;
    const mobilePattern = /^\d{10,11}$/;
    if (
      !mobilePattern.test(mobile) || !emailPattern.test(mail) || !agree
    ) {
      return false; // error
    } else return true;
  };

  const validAllCheck = () => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const mobilePattern = /^\d{10,11}$/;

    if (isValid()) {
      alert('complete');
    } else {
      if (!mobilePattern.test(mobile)) {
        setMobileValid('error');
      }
      if (!emailPattern.test(mail)) {
        setMailValid('error');
      }
      if (!agree) {
        setAgreeValid('error');
      }
      formRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className='frame'>
      <div className='sec-complete'>
        <img className='logo24' src='/img/logo.png' alt='' />
        <div className='complete-title'>1회차 교육 신청이 마감되었습니다</div>
        <div className='alarm-text'>아래 연락처를 작성해 주시면<br /><span className='underline'>2회차 교육 시 사전에 교육정보를</span><br />전달해 드리겠습니다.</div>
      </div>
      <div className='sec-form' ref={formRef}>
        <div className='form-title'>2회차 교육 알림 신청</div>
        <TextInput type='tel' label='휴대전화 번호' placeholder='휴대전화 번호 입력...' value={mobile} onChange={mobileChange} valid={mobileValid} validMsg='휴대전화 번호를 확인해 주세요' />
        <TextInput type='email' label='이메일' placeholder='이메일 입력...' value={mail} onChange={mailChange} valid={mailValid} validMsg='이메일을 확인해 주세요' />
        <div className='personal-info-wrap'>
          <div className='personal-info-text-wrap'>
            <div className='personal-info-text-title'>개인정보 수집 및 이용에 대한 동의</div>
            <div className='personal-info-text'>
              <div className='personal-info-text-group'>
                <div className='personal-info-text-number'>1.</div>
                <div className='personal-info-text-data'><span className='semi w80'>수집 및 이용 항목</span>: 이름, 생년월일, 휴대전화 번호, 이메일, 소속</div>
              </div>
              <div className='personal-info-text-group'>
                <div className='personal-info-text-number'>2.</div>
                <div className='personal-info-text-data'><span className='semi w80'>수집 및 이용 목적</span>: Golden Wayve Limited에서 실시하는 보험 <span className='underline'>교육 참가자 파악 및 교육 안내를 위하여 최소한의 범위 내에서 개인정보를 수집하고 있습니다.</span></div>
              </div>
              <div className='personal-info-text-group'>
                <div className='personal-info-text-number'>3.</div>
                <div className='personal-info-text-data'><span className='semi w80'>개인정보의 보유 및 이용 기간</span>: 교육 참가자의 개인정보 수집 및 이용에 관한 동의일로부터 <span className='underline'>2회차 교육 종료 시까지</span> 위 목적을 위하여 보유 및 이용하게 됩니다.</div>
              </div>
              <div className='personal-info-text-group'>
                <div className='personal-info-text-number'>4.</div>
                <div className='personal-info-text-data'><span className='semi w80'>동의를 거부할 권리 및 동의를 거부할 경우의 불이익</span>: 수집하는 개인정보는 교육 참가자 파악 및 교육 안내를 위해 필수적이므로, 위 사항에 동의하셔야만 교육 참가 신청을 할 수 있습니다.</div>
              </div>
            </div>
          </div>
          <div className={`personal-agree-btn ${agree ? 'active' : ''}`} onClick={() => agreeToggle()}>
            <img className='personal-agree-icon' src='/img/check.png' alt='' />
            <div className='personal-agree-text'>Golden Wayve Limited가 위와 같이 개인정보를 수집·이용 하는 것에 동의합니다.</div>
          </div>
            {
              agreeValid === 'error' ?
              <div className='input-error'>개인정보 수집 및 이용에 대한 내용에 동의하지 않으면 교육 참가 신청을 할 수 없습니다.</div> : null
            }
        </div>
        <Button
          onClick={validAllCheck}
          text='알림 신청'
          disabled={!isValid()}
        />
        <div className='footer'>
          <div className='footer-text'>contact@goldenwayve.com</div>
          <div className='footer-text'>Unit 706, 7/Floor, South Seas Centre, Tower 2, 75 Mody Road, Tsimshatsui, Kowloon, HK.</div>
          <div className='footer-text'>copyright 2024 Golden Wayve Limited</div>
        </div>
      </div>
    </div>
  );
};

export default AlarmForm;