import React, { useEffect, useRef, useState } from 'react';
import Button from '../components/button';
import CommentProfile from '../components/comment';
import QAItem from '../components/qa_item';
import TextInput from '../components/input';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { getFirestore, setDoc, doc } from 'firebase/firestore/lite';
import { getApp } from 'firebase/app';
import routes from '../configs/routes';

const ApplicationForm = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const [agree, setAgree] = useState(false);
  const agreeToggle = () => {
    setAgree(!agree);
    setAgreeValid('');
  };
  const [agreeValid, setAgreeValid] = useState('');

  const formRef = useRef<HTMLDivElement | null>(null);
  const moveForm = () => {
    formRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  const [name, setName] = useState('');
  const [birthday, setBirthday] = useState('');
  const [mobile, setMobile] = useState('');
  const [mail, setMail] = useState('');
  const [team, setTeam] = useState<number | null>(null);

  const nameChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    setNameValid('');
  };
  const birthdayChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setBirthday(e.target.value);
    setBirthdayValid('');
  };
  const mobileChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setMobile(e.target.value);
    setMobileValid('');
  };
  const mailChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setMail(e.target.value);
    setMailValid('');
  };
  const selectTeam = (n: number) => {
    setTeam(n);
    setTeamValid('');
  };

  const [nameValid, setNameValid] = useState('');
  const [birthdayValid, setBirthdayValid] = useState('');
  const [mobileValid, setMobileValid] = useState('');
  const [mailValid, setMailValid] = useState('');
  const [teamValid, setTeamValid] = useState('');

  const date = [
    '11월 16일 (토) 오전 10시',
    '11월 23일 (토) 오전 10시',
    '11월 30일 (토) 오전 10시',
  ];

  const [selectedDate, setSelectedDate] = useState(date[0]);

  const isValid = () => {
    const mobilePattern = /^\d{10,11}$/;
    if (
      name === ''  || !mobilePattern.test(mobile) || !agree
    ) {
      return false; // error
    } else return true;
  };

  const validAllCheck = async () => {
    const mobilePattern = /^\d{10,11}$/;

    if (isValid()) {
      const tId = searchParams.get('f') === 'app' ? 'app' : searchParams.get('tId') ?? '-';

      const db = getFirestore(getApp());
      await setDoc(doc(db, 'kr_seminars', new Date().getTime().toString()), {
        tId,
        name,
        mobile,
        date: selectedDate,
        created: new Date().toLocaleString(),
        greet: {
          no: localStorage.getItem('greet'),
          firstAccessTime: localStorage.getItem('greet_at'),
        },
      });

      localStorage.setItem('flag', selectedDate);
      navigate(routes.complete);
    } else {
      if (name === '') {
        setNameValid('error');
      }
      if (!mobilePattern.test(mobile)) {
        setMobileValid('error');
      }
      if (!agree) {
        setAgreeValid('error');
      }
      formRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className='frame'>
      <div>
        <img src="/img/section/section1.avif" alt="section1" style={{width: '100vw'}} />
      </div>
      <div>
        <div style={{padding: '64px 20px 24px 20px'}}>
          <img src="/img/section/section3-1.avif" alt="section3-1" style={{width: 'calc(100vw - 40px)'}} />
        </div>
        <div className='com-scroll' style={{ display: 'flex', flexDirection: 'row', overflowX: 'scroll', paddingBottom: '32px', paddingLeft: '20px'}}>
          <img src="/img/section/company1.avif" alt="com1" style={{width: 'calc(100vw - 72px)', marginRight: '16px'}} />
          <img src="/img/section/company2.avif" alt="com2" style={{width: 'calc(100vw - 72px)', marginRight: '16px'}} />
          <img src="/img/section/company3.avif" alt="com3" style={{width: 'calc(100vw - 72px)', marginRight: '16px'}} />
        </div>
      </div>
      <div>
        <img src="/img/section/section4.avif" alt="section4" style={{width: '100vw'}} />
      </div>
      <div>
        <img src="/img/section/section4-2.avif" alt="section4-2" style={{width: '100vw'}} />
      </div>
      <div>
        <img src="/img/section/section5.avif" alt="section5" style={{width: '100vw'}} />
      </div>
      <div>
        <img src="/img/section/section6.avif" alt="section6" style={{width: '100vw'}} />
      </div>
      <div>
        <img src="/img/section/section7.avif" alt="section7" style={{width: '100vw'}} />
      </div>
      <div className='sec-form' ref={formRef}>
        <div className='form-title'>세미나 참가 신청서</div>
        <div className='text-input-wrap'>
          <div className='input-label'>참석 날짜</div>
          <div className='text-input' style={{paddingRight: '12px',}}>
            <select name="cars" id="date" style={{ border: 'none', backgroundColor: '#fafafc', fontSize: '17px', width: '100%'}} value={selectedDate} onChange={({target: {value}}) => setSelectedDate(value)}>
              {
                date.map((d: string, i: number) => <option value={d} key={String(i)}>{d}</option>)
              }
            </select>
          </div>

        </div>
        <TextInput type='text' label='이름' placeholder='이름 입력...' value={name} onChange={nameChange} valid={nameValid} validMsg='이름을 확인해 주세요' />
        <TextInput type='tel' label='휴대전화 번호' placeholder='휴대전화 번호 입력...' value={mobile} onChange={mobileChange} valid={mobileValid} validMsg='휴대전화 번호를 확인해 주세요' maxLength={11}/>
        <div className='personal-info-wrap'>
          <div className='personal-info-text-wrap'>
            <div className='personal-info-text-title'>개인정보 수집 및 이용에 대한 동의</div>
            <div className='personal-info-text'>
              <div className='personal-info-text-group'>
                <div className='personal-info-text-number'>1.</div>
                <div className='personal-info-text-data'><span className='semi w80'>수집 및 이용 항목</span>: 이름, 휴대전화 번호</div>
              </div>
              <div className='personal-info-text-group'>
                <div className='personal-info-text-number'>2.</div>
                <div className='personal-info-text-data'><span className='semi w80'>수집 및 이용 목적</span>: Golden Wayve Limited에서 실시하는 <span className='underline'>세미나 참가자 파악 및 세미나 안내를 위하여 최소한의 범위 내에서 개인정보를 수집하고 있습니다.</span></div>
              </div>
              <div className='personal-info-text-group'>
                <div className='personal-info-text-number'>3.</div>
                <div className='personal-info-text-data'><span className='semi w80'>개인정보의 보유 및 이용 기간</span>: 세미나 참가자의 개인정보 수집 및 이용에 관한 동의일로부터 <span className='underline'>세미나 종료 시까지</span> 위 목적을 위하여 보유 및 이용하게 됩니다.</div>
              </div>
              <div className='personal-info-text-group'>
                <div className='personal-info-text-number'>4.</div>
                <div className='personal-info-text-data'><span className='semi w80'>동의를 거부할 권리 및 동의를 거부할 경우의 불이익</span>: 수집하는 개인정보는 세미나 참가자 파악 및 세미나 안내를 위해 필수적이므로, 위 사항에 동의하셔야만 세미나 참가 신청을 할 수 있습니다.</div>
              </div>
            </div>
          </div>
          <div className={`personal-agree-btn ${agree ? 'active' : ''}`} onClick={() => agreeToggle()}>
            <img className='personal-agree-icon' src={agree ? '/img/check-fill.png' : '/img/check.png'} alt='' />
            <div className='personal-agree-text'>Golden Wayve Limited가 위와 같이 개인정보를 수집·이용 하는 것에 동의합니다.</div>
          </div>
            {
              agreeValid === 'error' ?
              <div className='input-error'>개인정보 수집 및 이용에 대한 내용에 동의하지 않으면 세미나 참가 신청을 할 수 없습니다.</div> : null
            }
        </div>
        <Button
          onClick={validAllCheck}
          text='참가 신청'
          disabled={!isValid()}
        />
        <div className='footer'>
          <div className='footer-text'>contact@goldenwayve.com</div>
          <div className='footer-text'>copyright 2024 Golden Wayve Limited</div>
        </div>
      </div>
    </div>
  );
};

export default ApplicationForm;