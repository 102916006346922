import React, { useEffect, useState } from 'react';

const date = [
  '11월 16일 (토) 오전 10시',
  '11월 23일 (토) 오전 10시',
  '11월 30일 (토) 오전 10시',
];

const ApplicationComplete = () => {
  const [loading, setLoading] = useState(true);
  const [flag, setFlag] = useState('');

  useEffect(() => {
    setFlag(localStorage.getItem('flag') ?? date[0]);
    setLoading(false);
  }, []);

  if (loading) {
    return <div />;
  }

  return (
    <div className='frame' style={{padding: '20px'}}>
      {flag === date[0] ? <img src="/img/section/result-11-1.avif" alt="1" style={{width: 'calc(100vw - 40px)'}} /> : null}
      {flag === date[1] ? <img src="/img/section/result-11-2.avif" alt="1" style={{width: 'calc(100vw - 40px)'}} /> : null}
      {flag === date[2] ? <img src="/img/section/result-11-3.avif" alt="1" style={{width: 'calc(100vw - 40px)'}} /> : null}
    </div>
  )
};

export default ApplicationComplete;