import React from 'react';

interface Props{
  text: string;
  onClick: () => void;
  disabled?: boolean;
}

const Button:React.FC<Props> = (props) => {
  return (
    <div className={`button-primary ${props.disabled ? 'disabled' : ''}`} onClick={() => props.onClick()}>
      {props.text}
    </div>
  );
};

export default Button;